import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from 'components/organisms/global/Layout'
import Heading from 'components/atoms/headings/Heading'
import Details from 'components/organisms/_page-specific/collections/Details'
import Collections from 'components/organisms/_page-specific/collections/Collections'
import ImageHero from 'components/molecules/hero/ImageHero'
import IntroText from 'components/organisms/sections/IntroText'

const Index = ({ data }) => {
  const landing = data.datoCmsCollectionsLanding

  const metaImage = landing.metaImages[0]

  const metaObj = {
    title: landing.seoMetadata.title,
    description: landing.seoMetadata.description,
    image: {
      src: metaImage.fixed.src,
      width: metaImage.fixed.width,
      height: metaImage.fixed.height,
    },
  }

  return (
    <Layout
      headerProps={{ navImage: landing.mainNavigationImage }}
      metadata={metaObj}
    >
      <ImageHero
        images={landing.heroImages}
        explore={landing.heroSmallScrollText}
      >
        <Heading as="h1" headingSize="jumbo" color="white">
          {landing.heroMainHeading}
        </Heading>
      </ImageHero>
      <IntroText
        text={landing.introText}
        pb={{ xs: 3, sm: 4 }}
        style={{ zIndex: 20 }}
      />

      <Details data={landing} />
      <Collections data={data} />
    </Layout>
  )
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Index

export const pageQuery = graphql`
  {
    datoCmsCollectionsLanding {
      seoMetadata {
        description
        title
      }
      metaImages: heroImages {
        fixed(
          width: 1024
          height: 512
          imgixParams: {
            fit: "crop"
            w: "1024"
            h: "512"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          width
          height
          src
        }
      }
      introText
      heroMainHeading
      heroSmallScrollText
      heroImages {
        originalId
        fluid(
          maxWidth: 1400
          imgixParams: {
            fm: "jpg"
            auto: "compress"
            fit: "crop"
            ar: "16:9"
            w: "1400"
          }
        ) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }

      detailsSectionHeading
      detailsSectionTextComponents {
        ... on DatoCmsParagraph {
          model {
            apiKey
          }
          paragraphNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      detailsSectionImage {
        fluid(
          maxWidth: 600
          imgixParams: {
            fm: "jpg"
            auto: "compress"
            fit: "crop"
            ar: "3:4"
            w: "600"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      mainNavigationImage {
        fluid(
          maxWidth: 600
          imgixParams: {
            fit: "crop"
            ar: "3:4"
            w: "600"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    allDatoCmsCollection(sort: { order: ASC, fields: position }) {
      edges {
        node {
          originalId
          slug
          collectionName
          summary
          heroSectionBackgroundImage {
            fluid(
              maxWidth: 800
              imgixParams: {
                fit: "crop"
                ar: "6:4"
                fm: "jpg"
                auto: "compress"
                w: "800"
              }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`
