import React, { memo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Img from 'gatsby-image'
import Hidden from '@material-ui/core/Hidden'

import breakpoints from 'helpers/breakpoints'

import SmallHeading from 'components/atoms/headings/SmallHeading'
import Box from 'components/atoms/Box'
import Section from 'components/atoms/layout/Section'
import ScrollRevealText from 'components/atoms/text/ScrollRevealText'
import TextPlx from 'components/atoms/plx/TextPlx'

const StyledSection = styled(Section)`
  position: relative;
  z-index 10;

`

const ImageGrid = styled(Grid)`
  position: relative;

  ${breakpoints.lessThan('lg')`
    padding-bottom: 0 !important;
  `}
  ${breakpoints.lessThan('md')`
    order: 2;
    margin-bottom: -100px !important;
  `}
`

const StyledImg = styled(Img)`
  margin-left: -20px;
  // margin-bottom: -150px;


  ${breakpoints.greaterThan('lg')`
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;  
  `}    

  > div {
    padding-bottom: 85% !important;
  }


  ${breakpoints.greaterThan('sm')`
    margin-left: -30px;
    > div {
      padding-bottom: 65% !important;
    }    
  `}

  ${breakpoints.greaterThan('md')`
    margin-left: 0;

    > div {
      padding-bottom: 150% !important;
    }    
  `}

  ${breakpoints.greaterThan('lg')`


    > div {
      padding-bottom: 125% !important;
    }    
  `}
`

const Index = ({ data }) => {
  return (
    <StyledSection pb={{ xs: '0px' }}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <ImageGrid item xs={12} md={5} lg={5}>
            <StyledImg fluid={data.detailsSectionImage.fluid} />
          </ImageGrid>
          <Grid item lg={1} implementation="css" mdDown component={Hidden} />
          <Grid item xs={12} md={7} lg={6}>
            <Box pb={{ lg: 4 }} pl={{ xs: '0px', md: 3, lg: '0px' }}>
              <TextPlx>
                <SmallHeading noDivider leftBorder mb={4}>
                  {data.detailsSectionHeading}
                </SmallHeading>
              </TextPlx>
              <ScrollRevealText
                components={data.detailsSectionTextComponents}
                keyId="collections-details"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </StyledSection>
  )
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
}

export default memo(Index)
