import React, { memo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Plx from 'react-plx'
import { withWindowSizeListener } from 'react-window-size-listener'
import Grid from '@material-ui/core/Grid'
import Img from 'gatsby-image'
import Hidden from '@material-ui/core/Hidden'
import { Link } from 'gatsby'

import breakpoints from 'helpers/breakpoints'
import breakpointObj from 'constants/breakpoints'

import Box from 'components/atoms/Box'
import Flex from 'components/atoms/Flex'
import Heading from 'components/atoms/headings/Heading'
import Divider from 'components/atoms/dividers/HeadingDivider'
import MoreButton from 'components/atoms/buttons/MoreButtonLink'
import HoverEffect from 'components/atoms/media/HoverEffect'

const CollectionContainer = styled(Grid)`
  align-items: stretch;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;


  ${breakpoints.greaterThan('sm')`
    padding-left: 30px;
    padding-right: 30px;    
  `}  
  ${breakpoints.greaterThan('md')`
    padding-bottom: 40px;
    padding-left: 0;
    padding-right: 0;    
  `}  
  ${breakpoints.greaterThan('lg')`
    padding-bottom: 60px;
  `}
  ${breakpoints.greaterThan('xl')`
    padding-bottom: 80px;
  `}

  .content {
    position: relative;
    z-index: 2;
    margin: 5% 0;
    display: flex;
    align-items: center;
    justify-content: center;

    ${breakpoints.lessThan('xl')`
        margin-top: 0;
        margin-bottom: 0;
    `}     
  }

  .image {
    position: relative;
    z-index: 1;
  }



  .image {
    order: 1;
  }
  .content {
    margin-top: 0;
    margin-bottom: 0;
    order: 2;
  } 


  ${breakpoints.greaterThan('md')`
    &.odd {
      .content {
        order: 1;
      }

      .image {
        order: 2;
      }

      .overlap {
        margin-right: 0;
        margin-left: -100px;        

      }
    }

    &.even {
      .overlap {
        margin-left: 0;
        margin-right: -100px;      
      }      
    }
  `}


`

const PanelPlx = styled(Plx)`
  height: 100%;
  display: flex;
  align-items: stretch;
  visibility: visible !important;
`

const Panel = styled(Flex)`
  background white;
`

const StyledImg = styled(Img)`
  ${breakpoints.between('sm', 'md')`
    > div {
        padding-bottom: 50% !important;
    }
  `}
  ${breakpoints.between('md', 'lg')`
    > div {
        padding-bottom: 100% !important;
    }
  `}
  ${breakpoints.between('lg', 'xl')`
    > div {
        padding-bottom: 80% !important;
    }
  `}
`

const Collection = ({ collection, index, windowSize, ...props }) => {
  const isOdd = index % 2 > 0

  const parallaxDataPanel = [
    {
      start: 'self',
      end: 'self',
      endOffset: '100vh',
      properties: [
        {
          startValue: 80,
          endValue: -60,
          property: 'translateY',
          unit: 'px',
        },
      ],
    },
  ]
  const parallaxDataContentEl = [
    {
      start: 'self',
      end: 'self',
      endOffset: '30vh',
      properties: [
        {
          startValue: 50,
          endValue: 0,
          property: 'translateX',
          unit: 'px',
        },
        {
          startValue: 0,
          endValue: 1,
          property: 'opacity',
        },
      ],
    },
  ]

  return (
    <CollectionContainer
      key={collection.node.originalId}
      container
      spacing={0}
      className={isOdd ? 'odd' : 'even'}
    >
      {isOdd && (
        <Grid item xl={1} implementation="css" mdDown component={Hidden} />
      )}
      <Grid item xs={12} md={6} lg={6} xl={5} className="content">
        <PanelPlx
          parallaxData={parallaxDataPanel}
          disabled={
            typeof window !== 'undefined' &&
            windowSize.windowWidth < breakpointObj.md
              ? true
              : false
          }
        >
          <Panel bg="white" p={{ xs: 3, md: 5, lg: '20%' }} full center>
            <Box textAlign="left">
              <Plx parallaxData={parallaxDataContentEl}>
                <Heading as="h3" headingSize="h3">
                  {collection.node.collectionName}
                </Heading>
              </Plx>

              <Plx parallaxData={parallaxDataContentEl}>
                <Divider mb={4} />
              </Plx>
              <Plx parallaxData={parallaxDataContentEl}>
                <Box as="p">{collection.node.summary}</Box>
              </Plx>
              <Plx parallaxData={parallaxDataContentEl}>
                <MoreButton
                  to={`/${collection.node.slug}`}
                  label="View range"
                />
              </Plx>
            </Box>
          </Panel>
        </PanelPlx>
      </Grid>
      <Grid item xs={12} md={6} className="image">
        <div className="overlap">
          <Link to={`/${collection.node.slug}`}>
            <HoverEffect>
              <StyledImg
                fluid={collection.node.heroSectionBackgroundImage.fluid}
              />
            </HoverEffect>
          </Link>
        </div>
      </Grid>
    </CollectionContainer>
  )
}

Collection.propTypes = {
  collection: PropTypes.object.isRequired,
  index: PropTypes.number,
  windowSize: PropTypes.object.isRequired,
}

export default typeof window !== 'undefined'
  ? memo(withWindowSizeListener(Collection))
  : Collection
