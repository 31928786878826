import React from 'react'
import styled from 'styled-components'

import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Img from 'gatsby-image'
import Hidden from '@material-ui/core/Hidden'

import breakpoints from 'helpers/breakpoints'
import theme from 'constants/theme'

import SmallHeading from 'components/atoms/headings/SmallHeading'
import Box from 'components/atoms/Box'
import Section from 'components/atoms/layout/Section'
import Collection from 'components/molecules/cards/Collection'
import BackgroundHeading from 'components/atoms/headings/BackgroundHeading'
import SectionHeading from 'components/atoms/headings/SectionHeading'

const StyledBackgroundHeading = styled(BackgroundHeading)`
  transform: translateY(80px);
`

const Collections = ({ data }) => {
  const landing = data.datoCmsCollectionsLanding
  const collections = data.allDatoCmsCollection.edges

  return (
    <Section
      bg="greyLight"
      pt={{ xs: 18, md: 8, lg: 20 }}
      pb={theme.sectionPadding}
    >
      <StyledBackgroundHeading>Collections</StyledBackgroundHeading>
      <Container maxWidth="xl" className="foreground">
        <SectionHeading
          headingSize="h1"
          containerProps={{ mb: { xs: 4, sm: 5, md: 6, lg: 8, xl: 10 } }}
        >
          Collections
        </SectionHeading>
      </Container>
      <div>
        {collections.map((collection, index) => {
          return (
            <Collection
              key={collection.node.originalId}
              collection={collection}
              index={index}
            />
          )
        })}
      </div>
    </Section>
  )
}

Collections.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Collections
